import React, { useCallback, useMemo, useState } from 'react'
import { Listbox, Transition, Field } from '@headlessui/react'
import { SelectOptionType } from '~ui/types'

interface MultiSelectProps {
  label?: string
  disabled?: boolean
  multiple?: boolean
  selected: string[]
  options: SelectOptionType[]
  onSelect: (values: string[]) => void
}

export default function MultiSelect({
  label,
  options,
  selected,
  disabled,
  onSelect,
  multiple = true,
}: MultiSelectProps) {
  const selectedOptions = useMemo(() => {
    return options.filter((option: SelectOptionType) =>
      selected.includes(option.value),
    )
  }, [selected, options])

  const handleRemove = useCallback(
    (value: string) => {
      const removedSelection = selected.filter(
        (option: string) => option !== value,
      )
      onSelect(removedSelection)
    },
    [onSelect],
  )

  const handleChange = useCallback(
    (value: string | string[]) => {
      const newValue: string = typeof value === 'string' ? value : value[0]
      const selectedResult = selected.filter((s: string) => s === newValue)

      if (selectedResult.length) {
        handleRemove(newValue)
      } else {
        onSelect([...selected, newValue])
      }
    },
    [handleRemove, onSelect],
  )

  return (
    <Field disabled={disabled} className="w-full mx-auto">
      <Listbox
        as="div"
        multiple={multiple}
        className="space-y-1"
        value={selected}
        onChange={handleChange}
      >
        {({ open }) => (
          <>
            {label && (
              <Listbox.Label className="block text-sm leading-5 font-medium text-gray-700 dark:text-slate-400">
                {label}
              </Listbox.Label>
            )}
            <div className="relative">
              <span className="inline-block w-full rounded-md shadow-sm">
                <Listbox.Button className="cursor-default relative w-full flex justify-start items-center flex-wrap gap-2 rounded-md border pl-3 pr-10 py-2 text-left focus:outline-none transition ease-in-out duration-150 sm:text-sm sm:leading-5 bg-white border-gray-300 focus:shadow-outline-blue focus:border-blue-300 dark:bg-slate-900 dark:border-slate-500 dark:focus:shadow-outline-slate dark:focus:border-slate-700">
                  {!selectedOptions.length && <span>Select ...</span>}
                  {selectedOptions.map((option: SelectOptionType) => (
                    <div
                      key={option.label}
                      className="inline-flex items-center px-2 py-1 mr-1 rounded text-xs text-white bg-green-600 dark:text-slate-200"
                    >
                      {option.label}
                      <div
                        className="ml-1 rounded-full cursor-pointer bg-gray-100 dark:bg-slate-200 dark:text-slate-900"
                        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                          e.stopPropagation()
                          handleRemove(option.value)
                        }}
                      >
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711 4.29289L10 8.58579L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L11.4142 10L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L10 11.4142L5.70711 15.7071C5.31658 16.0976 4.68342 16.0976 4.29289 15.7071C3.90237 15.3166 3.90237 14.6834 4.29289 14.2929L8.58579 10L4.29289 5.70711C3.90237 5.31658 3.90237 4.68342 4.29289 4.29289Z"
                            fill="#15803d"
                          />
                        </svg>
                      </div>
                    </div>
                  ))}
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <svg
                      className="h-5 w-5 text-gray-400 dark:text-slate-400"
                      viewBox="0 0 20 20"
                      fill="none"
                      stroke="currentColor"
                    >
                      <path
                        d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </Listbox.Button>
              </span>

              <Transition
                show={open}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className="absolute mt-1 w-full rounded-md shadow-lg z-10 bg-white dark:bg-slate-800"
              >
                <Listbox.Options
                  static
                  className="max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
                >
                  {options.map((option: SelectOptionType) => {
                    const isSelected = selected.find(
                      (el: string) => el === option.value,
                    )
                      ? true
                      : false

                    return (
                      <Listbox.Option key={option.label} value={option.value}>
                        {({ active }) => (
                          <div
                            className={`${
                              active
                                ? 'text-white bg-blue-600 dark:bg-blue-900 dark:text-slate-300'
                                : 'text-gray-900 dark:text-slate-400'
                            } cursor-default select-none relative py-2 pl-8 pr-4`}
                          >
                            <span
                              className={`${
                                isSelected ? 'font-semibold' : 'font-normal'
                              } block truncate`}
                            >
                              {option.label}
                            </span>
                            {isSelected && (
                              <span
                                className={`${
                                  active
                                    ? 'text-white dark:text-slate-400'
                                    : 'text-blue-600 dark:text-blue-800'
                                } absolute inset-y-0 left-0 flex items-center pl-1.5`}
                              >
                                <svg
                                  className="h-5 w-5"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </span>
                            )}
                          </div>
                        )}
                      </Listbox.Option>
                    )
                  })}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </Field>
  )
}
