import React from 'react'
import clsx from 'clsx'
import { TableCellProps } from './table-cell'
export type { TableCellProps }

export const TableCell: React.FC<React.PropsWithChildren<TableCellProps>> = ({
  highlighted = false,
  colSpan = 1,
  rowSpan = 1,
  compact,
  onClick,
  className,
  children,
}) => {
  return (
    <td
      colSpan={colSpan}
      rowSpan={rowSpan}
      className={clsx(
        'relative px-3 pr-3 text-sm font-medium',
        className,
        {
          'text-gray-500 dark:text-slate-300': !highlighted,
          'text-gray-900 dark:text-slate-400': highlighted,
          'py-2': compact,
          'py-4': !compact,
        },
      )}
      onClick={onClick}
    >
      {children}
    </td>
  )
}
