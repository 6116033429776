import { ReactNode } from 'react'
import { Tab, TabGroup, TabPanels, TabPanel, TabList } from '@headlessui/react'

import clsx from 'clsx'

export type TabProps = {
  name: string
  to?: string
  current?: boolean
  panel: ReactNode
}

interface TabsProps {
  activeIndex: number
  onSelectTab: (index: number) => void
  tabs: TabProps[]
  className?: string
  children?: ReactNode
}

export function Tabs({
  tabs,
  activeIndex,
  onSelectTab,
  className,
  children,
}: TabsProps) {
  return (
    <>
      <TabGroup selectedIndex={activeIndex} onChange={onSelectTab}>
        <TabList
          className={clsx(
            'flex space-x-2 border-b-2 border-gray-100 mt-4 dark:border-slate-600 ',
            className,
          )}
        >
          {tabs.map((tab: TabProps) => (
            <Tab
              className={({ selected }) =>
                clsx(
                  'w-fit py-2.5 px-2 text-sm font-medium leading-5',
                  'border-b-2 focus:outline-none',
                  selected
                    ? 'border-primary-500 text-primary-600 dark:text-primary-500 dark:border-slate-500 '
                    : 'border-gray-100 text-gray-500 hover:border-gray-300 hover:text-gray-700 dark:border-slate-600 dark:text-gray-400 dark:hover:text-slate-300',
                )
              }
              key={tab.name}
            >
              {tab.name}
            </Tab>
          ))}
        </TabList>

        <TabPanels className={'mt-0'}>
          {tabs.map((tab: TabProps, index: number) => (
            <TabPanel
              className={clsx('whitespace-nowrap px-1 pb-4')}
              unmount={false}
              key={index}
            >
              {tab.panel}
            </TabPanel>
          ))}
        </TabPanels>
      </TabGroup>
      {children}
    </>
  )
}
