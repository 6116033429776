import React, { useCallback, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { DateTimeInputProps } from './date-time-input';
import FormElement from '~ui/FormElement';
import "react-datepicker/dist/react-datepicker.css?__remix_sideEffect__";

export const DateTimeInput = React.forwardRef<
  HTMLInputElement,
  DateTimeInputProps>(

  (
  {
    name,
    value,
    label,
    error,
    required,
    labelBgColor,
    helperText,
    description,
    containerClassName,
    onChange,
    children,
    ...props
  },
  ref) =>
  {
    const [curDate, setCurDate] = useState<Date | null>(null);

    useEffect(() => {
      if (value) {
        setCurDate(new Date(String(value)));
      } else {
        setCurDate(null);
      }
    }, [value]);

    const handleChange = useCallback(
      (date: Date | null) => {
        setCurDate(date);
        onChange!(date?.toISOString() || '');
      },
      [setCurDate, onChange]
    );

    return (
      <FormElement
        name={name}
        label={label}
        required={required}
        labelBgColor={labelBgColor}
        error={error}
        helperText={helperText}
        description={description}
        className={containerClassName}>

        <DatePicker
          selected={curDate}
          onChange={handleChange}
          className="border border-gray-300 rounded-md text-sm w-60 border-gray-300 dark:bg-slate-900 dark:text-slate-400 dark:border-slate-500 dark:placeholder:text-slate-400"
          timeInputLabel="Time:"
          dateFormat="MM/dd/yyyy h:mm aa"
          isClearable
          showTimeInput
          showIcon
          showYearDropdown
          yearDropdownItemNumber={25}
          placeholderText="Select date/time..."
          icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#6b7280"
            className="w-4 h-5 pr-2">

              <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5" />

            </svg>} />


        <input type="hidden" name={name} value={value} ref={ref} />
      </FormElement>);

  }
);

export default DateTimeInput;